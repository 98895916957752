<template>
  <step-lesson-layout :title="$t('module2.part3.lesson3.title')" :title-annotation="$t('module2.part3.lesson3.titleAnnotation')">
    <template v-slot:content>
      <!-- DRAWER 1 -->
      <div class="main-drawer-wrapper drawer1">
        <drawer>
          <template v-slot:head="{ isOpen }">
            <div class="main-drawer-head" :class="{ open: isOpen }">
              <div class="label">
                {{ $t('module2.part3.lesson3.drawerLabel') }} 1
              </div>
              <p class="title">{{ $t('module2.part3.lesson3.drawer1.title') }}</p>
              <div class="arrow">
                <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="main-drawer-content">
              <div class="step-list">
                <div class="step" v-for="(step, index) in steps1" :key="'steps1-' + step.name">
                  <div class="image-wrapper">
                    <img :src="step.image" :alt="step.name">
                  </div>
                  <div class="content">
                    <p class="label">{{ $t('module2.part3.lesson3.stepLabel') }} {{ index + 1 }}</p>
                    <p class="paragraphe" v-html="step.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </drawer>
      </div>

      <!-- DRAWER 2 -->
      <div class="main-drawer-wrapper drawer2">
        <drawer>
          <template v-slot:head="{ isOpen }">
            <div class="main-drawer-head" :class="{ open: isOpen }">
              <div class="label">
                {{ $t('module2.part3.lesson3.drawerLabel') }} 2
              </div>
              <p class="title">{{ $t('module2.part3.lesson3.drawer2.title') }}</p>
              <div class="arrow">
                <div class="icon" v-html="require('@/assets/icons/right-herringbone.svg?inline')"></div>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="main-drawer-content">
              <div class="step-list">
                <div class="step" v-for="(step, index) in steps2" :key="'steps2-' + step.name">
                  <div class="image-wrapper">
                    <img :src="step.image" :alt="step.name">
                  </div>
                  <div class="content">
                    <p class="label">{{ $t('module2.part3.lesson3.stepLabel') }} {{ index + 1 }}</p>
                    <p class="paragraphe" v-html="step.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </drawer>
      </div>

      <!-- END BLOCK -->
      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import Drawer from '@/components/Drawer'

export default {
  name: 'Module2Part3Lesson3',
  components: { StepLessonLayout, AppButtonLayout, Drawer },
  data () {
    return {
      steps1: [
        {
          name: 'maitre-oeuvre',
          image: require('@/assets/module2/part3/illu-mo.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step1')
        },
        {
          name: 'cc',
          image: require('@/assets/module2/part3/illu-cc.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step2')
        },
        {
          name: 'chara-rge',
          image: require('@/assets/module2/part3/chara-rge.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step3')
        },
        {
          name: 'devis',
          image: require('@/assets/module2/part3/illu-devis.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step4')
        },
        {
          name: 'travaux',
          image: require('@/assets/module2/part3/illu-travaux.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step5')
        },
        {
          name: 'facture-ah',
          image: require('@/assets/module2/part3/illu-facture-ah.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step6')
        },
        {
          name: 'dossier',
          image: require('@/assets/module2/part3/illu-dossier.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step7')
        },
        {
          name: 'prime',
          image: require('@/assets/module2/part3/illu-prime.svg'),
          content: this.$t('module2.part3.lesson3.drawer1.step8')
        }
      ],
      steps2: [
        {
          name: 'chara-rge',
          image: require('@/assets/module2/part3/chara-rge.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step1')
        },
        {
          name: 'cc-devis',
          image: require('@/assets/module2/part3/illu-cc-devis.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step2')
        },
        {
          name: 'travaux',
          image: require('@/assets/module2/part3/illu-travaux.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step3')
        },
        {
          name: 'facture-ah',
          image: require('@/assets/module2/part3/illu-facture-ah.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step4')
        },
        {
          name: 'dossier',
          image: require('@/assets/module2/part3/illu-dossier.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step5')
        },
        {
          name: 'prime',
          image: require('@/assets/module2/part3/illu-prime.svg'),
          content: this.$t('module2.part3.lesson3.drawer2.step6')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main-drawer-head {
  .title {
    text-transform: uppercase;
  }
}

.main-drawer-wrapper + .main-drawer-wrapper {
  margin-top: $space-xs;
}

.main-drawer-wrapper.drawer1 {
  .main-drawer-head {
    background-color: #00935d;
    .label {
      color: #00935d;
    }
  }
  .main-drawer-content {
    background-color: #00935d;
  }
}
.main-drawer-wrapper.drawer2 {
  .main-drawer-head {
    background-color: #008962;
    .label {
      color: #008962;
    }
  }
  .main-drawer-content {
    background-color: #008962;
  }
}
.main-drawer-content {
  padding-bottom: $space-s;
  .step-list {
    background-color: #fff;
    border-radius: $radius;
    padding: $space-m;
    .step {
      display: flex;
      align-items: center;
      .image-wrapper {
        min-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $space-m;
        img {
          max-height: 80px;
          width: 100%;
        }
      }
      .content {
        .label {
          font-size: $fz-s;
          font-weight: $fw-l;
          text-transform: uppercase;
        }
      }
      &:first-child {
        padding-bottom: $space-m;
      }
    }
    .step + .step {
      padding: $space-m 0;
      border-top: 1px solid $c-border;
    }
  }
}
</style>

<style lang="scss">
.main-drawer-wrapper.drawer1 {
  .main-drawer-head {
    .arrow {
      svg path {
        stroke: #00935d;
      }
    }
  }
}
.main-drawer-wrapper.drawer2 {
  .main-drawer-head {
    .arrow {
      svg path {
        stroke: #008962;
      }
    }
  }
}
</style>
